<template>
  <div>
    <!-- 标题区域 -->
    <div class="title-area">
      <p class="title-text">{{ newsInfo.title }}</p>
      <div class="counts">
        <div class="date">
          <img src="@/assets/news/data.png" alt="" class="data-icon" />
          <span class="time">{{ newsInfo.createtime }}</span>
        </div>
        <div class="date" style="margin-right: 0px">
          <img src="@/assets/news/watch.png" alt="" class="watch-icon" />
          <span class="time">{{ newsInfo.views }}</span>
        </div>
      </div>
    </div>
    <!-- 内容区域 -->
    <div class="news-content">
      <div
        class="content-text"
        id="content-text"
        v-html="newsInfo.content"
      ></div>
    </div>
  </div>
</template>

<script>
import { getNews } from "@/api";
export default {
  name: "Detail",
  data() {
    return {
      newsInfo: {},
    };
  },
  mounted() {
    this.getNewsDetail();
    console.log(this.$route.query.id);
  },
  updated() {
    var obj = document.getElementById("content-text");
    var imgs = obj.getElementsByTagName("img");
    for (var i = 0; i < imgs.length; i++) {
      imgs[i].style.width = "100%";
    }
  },
  methods: {
    // 获取新闻详情
    async getNewsDetail() {
      try {
        const result = await getNews({ id: this.$route.query.id });
        this.newsInfo = result.info;
      } catch (error) {
        alert(error);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.title-area {
  background: #ffffff;
  width: 100%;
  box-sizing: border-box;
  padding: 42px 0px 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title-text {
    font-size: 8px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
    padding-bottom: 11px;
  }
  .counts {
    display: flex;
    justify-content: center;
    align-items: center;
    .date {
      margin-right: 7px;
      .data-icon {
        width: 5px;
        height: 5px;
        margin-right: 4px;
        display: inline-block;
      }
      .watch-icon {
        width: 8px;
        height: 5px;
        margin-right: 4px;
        display: inline-block;
      }
      .time {
        font-size: 7px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #1d1d1d;
      }
    }
  }
}
.news-content {
  background: #f8f8f8;
  padding: 23px 130px 46px 129px;
  .content-text {
    font-size: 7px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #000000;
  }
}
</style>
